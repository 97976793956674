import React, { useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import queryString from 'query-string'
import { CircularProgress } from '@material-ui/core'

import Page from '../components/Page'
import { colors, fonts } from '../styles/variables'
import { useMst } from '../models/Root'
import PageHeader from '../components/PageHeader'
import { InputError } from '../types'
import { isValidPassword } from '../utils/inputValidation'
import { Button, Input } from '../components/Auth/shared'

const PageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 50px auto;
  transition: all 250ms ease-in-out;
  background-color: black;
  max-width: 1150px;
  padding: 50px;
  color: ${colors.white};
`

const Text = styled.span`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
`

const ResetPassword = observer(() => {
  const {
    authStore: { updatePassword, fetching }
  } = useMst()

  const { token }: { token?: string } = queryString.parse(location.search)

  const [success, setSuccess] = useState<boolean | null>(null)
  const [password, setPassword] = useState<string>('')
  const [passwordAgain, setPasswordAgain] = useState<string>('')
  const [errors, setErrors] = useState<InputError[]>([])

  const validateInputs = () => {
    let validationErrors: InputError[] = []

    setPassword(password.trim())
    setPasswordAgain(passwordAgain.trim())

    if (password.trim().length && !isValidPassword(password.trim())) {
      validationErrors.push({ input: 'password', message: 'Salasanan on oltava väh. 6 merkkiä' })
    }
    if (password.length && passwordAgain.length && password !== passwordAgain) {
      validationErrors.push({ input: 'password', message: 'Salasanat eivät täsmää' })
      validationErrors.push({ input: 'passwordAgain', message: 'Salasanat eivät täsmää' })
    }
    setErrors(validationErrors)
    return validationErrors.length
  }

  const handleSubmit = async () => {
    if (!token || !password || errors.length) return
    const res = await updatePassword(token, password)
    setSuccess(res)
  }

  const disabled = !token?.length || password?.length < 6 || password !== passwordAgain || fetching || !!errors.length

  const renderContent = () => {
    if (fetching) return <CircularProgress color="inherit" />
    if (success) return <Text>Salasana vaihdettu! Voit kirjautua nyt sisään käyttäen uutta salasanaa.</Text>
    if (success === false) return <Text>Jokin meni pieleen :(</Text>
    return (
      <>
        <Input
          type="password"
          label="Salasana"
          value={password}
          onChange={setPassword}
          onBlur={validateInputs}
          errorMessage={errors.find((err) => err.input === 'password')?.message}
        />
        <Input
          type="password"
          label="Salasana uudelleen"
          value={passwordAgain}
          onChange={setPasswordAgain}
          onBlur={validateInputs}
          errorMessage={errors.find((err) => err.input === 'passwordAgain')?.message}
        />
        <Button disabled={disabled} onClick={handleSubmit} fetching={fetching}>
          Lähetä
        </Button>
      </>
    )
  }

  return (
    <Page>
      <PageContainer>
        <PageHeader title={token ? 'Aseta uusi salasana' : '🤔'} />
        {token ? <Container>{renderContent()}</Container> : null}
      </PageContainer>
    </Page>
  )
})

export default ResetPassword
